@import "./assets/fonts/font-awesome/css/fontawesome-all.css"; // font awesome

// Fira Sans

@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 300;
  src:
    url("./assets/fonts/woff2/FiraSans-Light.woff2") format("woff2"),
    url("./assets/fonts/woff/FiraSans-Light.woff") format("woff"),
    url("./assets/fonts/ttf/FiraSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 300;
  src:
    url("./assets/fonts/woff2/FiraSans-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/woff/FiraSans-LightItalic.woff") format("woff"),
    url("./assets/fonts/ttf/FiraSans-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  src:
    url("./assets/fonts/woff2/FiraSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/woff/FiraSans-Regular.woff") format("woff"),
    url("./assets/fonts/ttf/FiraSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 400;
  src:
    url("./assets/fonts/woff2/FiraSans-RegularItalic.woff2") format("woff2"),
    url("./assets/fonts/woff/FiraSans-RegularItalic.woff") format("woff"),
    url("./assets/fonts/ttf/FiraSans-RegularItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  src:
    url("./assets/fonts/woff2/FiraSans-Medium.woff2") format("woff2"),
    url("./assets/fonts/woff/FiraSans-Medium.woff") format("woff"),
    url("./assets/fonts/ttf/FiraSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 500;
  src:
    url("./assets/fonts/woff2/FiraSans-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/woff/FiraSans-MediumItalic.woff") format("woff"),
    url("./assets/fonts/ttf/FiraSans-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  src:
    url("./assets/fonts/woff2/FiraSans-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/woff/FiraSans-SemiBold.woff") format("woff"),
    url("./assets/fonts/ttf/FiraSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 600;
  src:
    url("./assets/fonts/woff2/FiraSans-SemiBoldItalic.woff2") format("woff2"),
    url("./assets/fonts/woff/FiraSans-SemiBoldItalic.woff") format("woff"),
    url("./assets/fonts/ttf/FiraSans-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans";
  font-weight: 700;
  font-style: normal;
  src:
    url("./assets/fonts/woff2/FiraSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/woff/FiraSans-Bold.woff") format("woff"),
    url("./assets/fonts/ttf/FiraSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans";
  font-weight: 700;
  font-style: italic;
  src:
    url("./assets/fonts/woff2/FiraSans-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/woff/FiraSans-BoldItalic.woff") format("woff"),
    url("./assets/fonts/ttf/FiraSans-BoldItalic.ttf") format("truetype");
}
