@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500&display=swap");

@import "./fonts";
@import "./theme.scss";
@import "./mat-overrides.scss";

// Non Angular Material styling reset
* {
  padding: 0;
  margin: 0;
  border: none;
  list-style: none;
  box-sizing: border-box;
}

// TODO Review this once the project is in Notessa
html,
body {
  width: 100%;
  height: 100%;
}

.view-main {
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  overflow: auto;
  > .wrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    .view-header {
      flex: 0 0 auto;
      display: flex;
      flex-flow: row wrap;
      margin: 50px 0 20px 0;
      .view-heading {
        flex: 1 0 auto;
        font:
          24px/40px "Fira Sans",
          sans-serif;
        color: #00325a;
      }
    }
    .view-header-ds {
      flex: 0 0 auto;
      display: flex;
      flex-flow: row wrap;
      padding-bottom: 1.5rem;
      background: #f6f8fa;
      .view-heading {
        flex: 1 0 auto;
        font:
          24px/40px "Fira Sans",
          sans-serif;
        color: #00325a;
      }
    }
    .dashboard {
      flex: 1 1 auto;
      width: 100%;
      height: 100%;
      background: white;
      border: 0;
      .content {
        position: relative;
        flex: 1 0 auto;
        width: 100%;
        height: 100%;
        padding-bottom: 1px;
        .alert {
          padding: 16px;
          .message {
            padding: 12px 20px;
            border-radius: 4px;
            font:
              500 14px/20px "Fira Sans",
              sans-serif;
            &.error {
              background: #f2dede;
              border: 1px solid #e4b9b9;
              color: #721c24;
              span {
                padding: 0 8px;
                a {
                  color: #721c24;
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .progress-widget {
          display: flex;
          flex-flow: column nowrap;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // border-radius: 8px; // Requires the new reports selection dropdown to be enabled
          .progress-bar {
            flex: 0 0 auto;
          }
          .progress-message {
            flex: 1 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              font:
                bold 20px/24px "Fira Sana",
                sans-serif;
              color: rgba(0, 0, 0, 0.6);
              text-align: center;
            }
          }
        }
        iframe {
          position: relative;
          z-index: 1;
          width: 100%;
          height: 100%;
          border: none;
        }
      }
    }
  }
}
