.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: none;
}

.mat-tab-links {
  border-bottom: 1px solid #e0e0e0;
}

.mat-tab-link.mat-tab-label-active {
  opacity: 1;
}
